<template>
  <div class="insurance">
    <div class="img" />
    <!-- <div class="navbar">
      <div class="tabbar">
        <div v-for="item in contentList" :key="item.uuid" :class="{'check': article.uuid === item.uuid, 'tab': true}" @click="getTab(item.uuid)">
            {{item.title}}
        </div>
      </div>
      <div class="msgs">
        <div class="basic">
          <div class="title"><div class="blue" />{{article.title}}</div>
          <div class="main" v-html="article.content">
          </div>
        </div>
      </div>
    </div> -->
    <div class="search">
      <div class="search-title">
        <span style="margin-top:10px;min-width: 70px;text-align: right;">险种：</span>
        <div class="titles">
          <div @click="labelVal=''" :class="labelVal?'titles-tit':'titles-text'">全部分类</div>
          <div v-for="item in insuranceType" :key="item.label" :class="labelVal === item.value?'titles-text':'titles-tit'">
            <div @click="handleActive(item)">{{item.label}}</div>
          </div>
        </div>
      </div>
      <div class="search-title">
        <span style="margin-top:10px;min-width: 70px;text-align: right;">保险公司：</span>
        <div class="titles">
          <div @click="labelId=''" :class="labelId?'titles-tit':'titles-text'">全部</div>
          <div v-for="item in conPanylist" :key="item.id" :class="labelId === item.id?'titles-text':'titles-tit'">
            <div @click="handleCompany(item)">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="list">
        <div class="tab" v-for="item in contentList" :key="item.id">
          <div class="tab-box1">{{item.type}}</div>
          <div class="tab-box5">
            <div class="tab-box2">
              <img :src="baseURL+'/api/file/preview?source='+item.insuranceOrgLogoUrl" :title="item.insuranceOrgName" style="cursor:pointer;">
              <div>{{item.name}}</div>
            </div>
            <div class="tab-line"></div>
            <div class="tab-box3">
              <div class="box3" v-if="item.productType[0] ==='1'">
                <img src="../../assets/img/dui.png" alt="">
                <span>电子发票</span>
              </div>
              <div class="box3" v-if="item.productType[1] ==='1'">
                <img src="../../assets/img/dui.png" alt="">
                <span>纸质发票</span>
              </div>
              <div class="box3" v-if="item.productType[2] ==='1'">
                <img src="../../assets/img/dui.png" alt="">
                <span>电子保单</span>
              </div>
              <div class="box3" v-if="item.productType[3] ==='1'">
                <img src="../../assets/img/dui.png" alt="">
                <span>纸质保单</span>
              </div>
            </div>
            <div class="tab-box4" @click="toDetail(item)">
              <div>查看详情</div>
            </div>
          </div>
        </div>
        <div class="nomessage" v-if="total=='0'">
          <img src="../../assets/img/img缺省.png" alt="">
          <span>抱歉，没有搜索到相关的产品</span>
        </div>
      </div>
      <div class="background" v-if="total!='0'">
        <el-pagination background layout="prev, pager, next" @prevClick="pager" @nextClick="pager" @current-change="pager" prev-text="上一页" next-text="下一页" :page-size="6" :total="total">
        </el-pagination>
      </div>
    </div>
    <div class="foot">
      <span>Copyright © 上海仁信保险经纪有限公司 版权所有 <a href="https://beian.miit.gov.cn" style="color: #B1A9A9;">沪ICP备12012639号-9 </a> </span>
    </div>
  </div>
</template>

<script>
import { conPanylist, insurance, dict } from '@/api/home'
export default {
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      insuranceType: [],
      total: 0,
      conPanylist: [],
      contentList: [],
      labelVal: '',
      labelId: ''
    }
  },
  watch: {
    'labelVal': {
      handler() {
        this.refresh()
      }
    },
    'labelId': {
      handler() {
        this.refresh()
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      conPanylist().then(res => {
        console.log(res)
        this.conPanylist = res
      })
      dict('insurance_type').then(res => {
        this.insuranceType = res.content
        this.refresh()
      })
    },
    refresh() {
      insurance({ page: 1, size: 6, sort: 'id,desc', type: this.labelVal, insuranceOrgId: this.labelId }).then(res => {
        this.contentList = res.content
        this.total = res.totalElements
        // this.total = 100
        this.contentList.forEach(item => {
          item.productType = item.productType.split("")
          this.insuranceType.forEach(i => {
            if (item.type === i.value) {
              item.type = i.label
            }
          })
        })
      })
    },
    getTab(uuid) {
      console.log(uuid);
      this.contentList.map(item => {
        if (item.uuid === uuid) {
          this.article = item
        }
      })
    },
    toDetail(row) {
      this.$router.push({ path: '/insurance/details', query: { id: row.id } })
    },
    pager(e) {
      console.log(e)
      this.loading = true
      insurance({ page: e, size: 6, sort: 'id,desc', type: this.labelVal, insuranceOrgId: this.labelId }).then(res => {
        this.loading = false
        console.log(res)
        this.contentList = res.content
        this.total = res.totalElements
      })
    },
    handleActive(item) {
      this.labelVal = item.value
    },
    handleCompany(item) {
      this.labelId = item.id
    }
  }
}
</script>

<style lang="less" scoped>
@import url("../../assets/css/ql.less");
.insurance {
  background: #f4f5f6;
}
.img {
  height: 180px;
  background: url("../../assets/img/img保险产品.png") no-repeat center;
}
.search {
  width: 1180px;
  // height: 194px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin: 30px auto 0;
  padding: 30px 50px 10px;
  box-sizing: border-box;
  .search-title {
    display: flex;
    span {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .titles {
      display: flex;
      flex-wrap: wrap;
      .titles-text {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        background: #ffffff;
        border-radius: 50px 50px 50px 50px;
        opacity: 1;
        border: 1px solid #2e4e9a;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #2e4e9a;
        margin-left: 20px;
        margin-bottom: 20px;
      }
      .titles-tit {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        background: #ffffff;
        border-radius: 50px 50px 50px 50px;
        opacity: 1;
        border: 1px solid #dddddd;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #999999;
        margin-left: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
.main {
  padding: 40px 0 40px;
  background-color: #f4f5f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .list {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    min-height: 636px;
    // justify-content: center;
    .tab {
      width: 380px;
      height: 348px;
      background: #ffffff;
      box-shadow: 0px 8px 18px 2px rgba(136, 161, 188, 0.18);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      margin: 15px 10px;
      transition: all 0.5s;
      .tab-box1 {
        width: fit-content;
        padding: 4px 10px;
        box-sizing: border-box;
        background: rgba(236, 105, 65, 0.1);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        margin: 16px 0 0 16px;
      }
      .tab-box5 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .tab-box2 {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-bottom: 20px;
          img {
            width: 180px;
            height: 60px;
            // border: 1px solid red;
            margin: 15px 0 12px;
          }
        }
        .tab-line {
          width: 300px;
          height: 1px;
          background: #f5f5f5;
          opacity: 1;
          // border: 1px solid #f5f5f5;
        }
        .tab-box3 {
          display: flex;
          // justify-content: center;
          width: 300px;
          flex-wrap: wrap;
          transform: translateX(50%);
          margin-left: -50%;
          .box3 {
            display: flex;
            width: 101px;
            margin-top: 20px;
            img {
              margin-right: 5px;
            }
          }
        }
        .tab-box4 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 140px;
          height: 44px;
          border-radius: 55px 55px 55px 55px;
          opacity: 1;
          border: 1px solid #dddddd;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 30px 0;
        }
        .tab-box4:hover {
          border-radius: 55px 55px 55px 55px;
          opacity: 1;
          border: 1px solid #2e4e9a;
          color: #2e4e9a;
        }
      }
      button {
        width: 96px;
        height: 40px;
        border: 1px solid #dddddd;
        border-radius: 20px;
        font-size: 0.875rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        background-color: #ffffff;
        cursor: pointer;
        transition: all 0.5s;
      }
      button:hover {
        border: 1px solid #2e4e9a;
        color: #2e4e9a;
        transition: all 0.5s;
      }
    }
    .tab:hover {
      box-shadow: 0px 6px 30px 0px rgba(115, 139, 165, 0.4);
      transition: all 0.5s;
    }
  }
}
.nomessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 160px;
  span {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}
.navbar {
  background: #f4f5f6;
  display: flex;
  justify-content: center;
  padding: 40px 0 80px;
  .tabbar {
    margin-right: 20px;
    .tab {
      width: 230px;
      height: 62px;
      background: #ffffff;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      border-bottom: solid 1px #f4f5f6;
      padding-left: 50px;
      cursor: pointer;
    }
    .check {
      font-weight: bold;
      color: #2e4e9a;
      border-right: solid 3px #2e4e9a;
    }
  }
  .msgs {
    width: 930px;
    min-height: 706px;
    background: #ffffff;
    .basic {
      padding: 30px 50px;
      .title {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        .blue {
          width: 4px;
          height: 16px;
          background: #2e4e9a;
          border-radius: 2px;
          margin-right: 10px;
        }
      }
      .main {
        margin-top: 40px;
        user-select: text;
        /deep/ p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: Regular;
          color: #666666;
          line-height: 24px;
        }
        /deep/ a {
          color: #06c;
          text-decoration: underline;
        }
      }
    }
  }
}
.background {
  margin-top: 30px;
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #2e4e9a;
  }
  /deep/ .el-pager li {
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    border-radius: 5px;
  }
  /deep/ .el-pagination button {
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 0 12px;
  }
}
.foot {
  height: 64px;
  background: #393d41;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b1a9a9;
}
</style>
